import { QueryKeys } from "@/types";

import { BaseQueryClient } from "../base/queries";

import { ReaderFile } from "./types";

const queryKey = QueryKeys.ReaderFiles;

class ReaderFileQueryClient extends BaseQueryClient<ReaderFile> {
  constructor() {
    super({ queryKey });
  }
}
export const ReaderFileQueries = new ReaderFileQueryClient();
