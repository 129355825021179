import { QueryClient } from "@tanstack/react-query";

import { QueryKeys } from "@/types";

import { createAxiosMutation } from "@/hooks/react-query";
import { apiClient } from "@/lib/api";

import { API_BASE_PATH, BaseQueryClient } from "../base/queries";

import { DataFormat, OSDPFlags, Reader } from "./types";

const queryKey = QueryKeys.Readers;

const afterModify = async (queryClient: QueryClient) =>
  queryClient.invalidateQueries([QueryKeys.Doors]);

class ReaderQueryClient extends BaseQueryClient<Reader> {
  constructor() {
    super({ queryKey, afterModify });
  }

  useCreateMutation = createAxiosMutation(
    async ({ values }: { values: Partial<Reader> }) => {
      const params = {
        ...values,
        panel_id: values?.panel?.id,
        controller_id: values?.panel?.controller.id,
      };
      const { data } = await apiClient.post<Partial<Reader>>(
        `${API_BASE_PATH}/${this.queryKey}`,
        this.encode(params)
      );
      return data;
    },
    {
      successToast: () => ({
        status: "success",
        message: this.createMessageSuccess(),
      }),
      errorToast: (err: any) => ({
        status: "error",
        message: this.createMessageFailure(err),
      }),
      onSettled: async (queryClient, result, err, member_id) => {
        await queryClient.invalidateQueries([this.queryKey]);
        if (this.afterModify) {
          await this.afterModify(queryClient);
        }
      },
    }
  );

  useIdReportMutation = createAxiosMutation(
    async ({ readerId }: { readerId: number }) => {
      const { data } = await apiClient.patch<any>(
        `${API_BASE_PATH}/${this.queryKey}/${readerId}/fetch_id_report`
      );
      return data;
    },
    {
      successToast: () => ({
        status: "success",
        message: "ID Report fetch initiated",
      }),
      errorToast: (err: any) => ({
        status: "error",
        message: `Failed to fetch ID Report: ${err.message}`,
      }),
      onSettled: async (queryClient) => {
        await queryClient.invalidateQueries([this.queryKey]);
        if (this.afterModify) {
          await this.afterModify(queryClient);
        }
      },
    }
  );

  // Flatten osdp_flags struct for compatibility with
  // @smartrent/forms <Form />, which doesn't support nested objects.
  decode = (data: Reader | Partial<Reader>) => {
    const { osdp_flags = {} as OSDPFlags, dt_fmt = [] } = data;

    const osdp_flags_address = osdp_flags?.address;
    const osdp_flags_baud = osdp_flags?.baud;
    const osdp_flags_auto_discovery_disabled =
      osdp_flags?.auto_discovery_disabled;
    const osdp_flags_secure = osdp_flags?.secure;
    const osdp_flags_tracing = osdp_flags?.tracing;

    return {
      ...data,
      dt_fmt_wiegand_pulses: dt_fmt.includes(DataFormat.wiegand_pulses),
      osdp_flags_address,
      osdp_flags_baud,
      osdp_flags_auto_discovery_disabled,
      osdp_flags_secure,
      osdp_flags_tracing,
    };
  };

  // Reconstruct osdp_flags and dt_fmt for @smartrent/forms <Form /> compatibility.
  // The form doesn't support nested objects.
  encode = (data: Reader | Partial<Reader>) => {
    const {
      osdp_flags_address,
      osdp_flags_baud,
      osdp_flags_auto_discovery_disabled,
      osdp_flags_secure,
      osdp_flags_tracing,
      dt_fmt = [],
      dt_fmt_wiegand_pulses,
      ...rest
    } = data;

    const osdp_flags = {
      address: osdp_flags_address,
      baud: osdp_flags_baud,
      auto_discovery_disabled: osdp_flags_auto_discovery_disabled,
      secure: osdp_flags_secure,
      tracing: osdp_flags_tracing,
    };

    const dataFormats = dt_fmt_wiegand_pulses
      ? [...dt_fmt, DataFormat.wiegand_pulses]
      : dt_fmt.filter((fmt) => fmt !== DataFormat.wiegand_pulses);

    return {
      ...rest,
      // Use rebuilt osdp_flags if any of the form fields are defined
      osdp_flags: Object.values(osdp_flags).some(Boolean)
        ? osdp_flags
        : data?.osdp_flags,
      dt_fmt: dataFormats,
    } as Reader;
  };
}
export const ReaderQueries = new ReaderQueryClient();
