import { Route } from "badmagic/dist/types";

import { CommunicationPreferenceOptions } from "@/modules/member/types";

import { booleanOptions, paginationParams } from "../shared";

export const memberRoutes: Route[] = [
  {
    name: "Get Members",
    path: `/members`,
    qsParams: [
      { label: "Site ID", name: "site_id" },
      { label: "First Name", name: "first_name" },
      { label: "Last Name", name: "last_name" },
      { label: "Nickname", name: "nickname" },
      { label: "Phone Number", name: "phone_number" },
      { label: "Email Address", name: "email_address" },
      { label: "Remote ID", name: "remote_id" },
      { label: "Unit", name: "location" },
      { label: "Department", name: "department" },
      { label: "Title", name: "title" },
      {
        label: "Communication Preference",
        name: "communication_preference",
        options: CommunicationPreferenceOptions,
      },
      {
        label: "In Intercom",
        name: "in_intercom",
        options: booleanOptions,
      },
      {
        label: "Has Access Levels",
        name: "has_access_levels",
        options: booleanOptions,
      },
      {
        label: "Has Credentials",
        name: "has_credentials",
        options: booleanOptions,
      },
      {
        label: "Has Mobile Credentials",
        name: "has_ble_credentials",
        options: booleanOptions,
      },
      {
        label: "Assigned Pin",
        name: "assigned_pin",
      },
      {
        label: "Assigned Card",
        name: "assigned_card",
        type: "number",
      },
      {
        label: "Assigned Card Format",
        name: "assigned_card_format",
        type: "number",
      },
      {
        label: "Access Level ID",
        name: "access_level_id",
        type: "number",
      },
      ...paginationParams([
        { value: "first_name", label: "First Name" },
        { value: "last_name", label: "Last Name" },
        { value: "email", label: "Email" },
      ]),
    ].filter((param) => param),
  } as Route,
  {
    name: "Get Member",
    path: `/members/:member_id`,
  },
  {
    name: "Create Member",
    path: `/members`,
    method: "POST",
    body: [
      { name: "site_id", type: "number" },
      { name: "first_name" },
      { name: "last_name" },
      { name: "nickname" },
      { name: "email_address" },
      { name: "phone_number" },
      { name: "remote_id" },
      { name: "location" },
      { name: "department" },
      { name: "title" },
      {
        label: "Communication Preference",
        name: "communication_preference",
        options: CommunicationPreferenceOptions,
      },
    ],
  },
  {
    name: "Update Member",
    path: `/members/:member_id`,
    method: "PATCH",
    body: [
      { name: "first_name" },
      { name: "last_name" },
      { name: "nickname" },
      { name: "email_address" },
      { name: "phone_number" },
      { name: "remote_id" },
      { name: "location" },
      { name: "department" },
      { name: "title" },
      {
        label: "Communication Preference",
        name: "communication_preference",
        options: CommunicationPreferenceOptions,
      },
    ],
  },
  {
    name: "Get Member by remote id",
    path: `/members/:remote_id/remote`,
    qsParams: [
      { label: "Site ID", name: "site_id", type: "number", required: true },
    ],
  },
  {
    name: "Delete member",
    path: `/members/:member_id`,
    method: "DELETE",
  },
];
